/* MENU PRINCIPAL */
app-nav-menu{
    float: left;
    width: 100%;
}
app-nav-menu ul{
    list-style-type: none;
    position: relative;
    z-index: 991;
    padding: 0;
    margin: 0;
}
app-nav-menu ul li{
    float: left;
}
app-nav-menu ul li a{
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 13px;
    padding: 5px 15px;
    line-height: 20px;
    display: block;
}
app-nav-menu ul li a:hover{
    color: #fff;
    background-color: #002D3F;
    text-decoration: none;
}
app-nav-menu ul li ul{
    background-color: #004059;
    position: absolute;
    visibility: hidden;
    padding: 0;
    margin: 0 !important;
    top: inherit !important;
    white-space: nowrap;
}
app-nav-menu ul li ul li{
    clear: both;
    position: relative;
    width: 100%;
}
app-nav-menu ul li ul li a{
    display: block;
}
app-nav-menu ul li:hover > ul{
    visibility: visible;
}
app-nav-menu ul li ul li ul.sub_menu-box{
    margin-left: 100% !important;
    top: 0 !important;
}
app-nav-menu ul li:hover{
    background-color: #004059;
}
app-nav-menu [class^="app-icone-"],
app-nav-menu [class*=" app-icone-"]{
    margin-right: 5px;
}
app-nav-menu .app-icone-seta-unica-direita:before {
    content: "\f0da";
}
app-nav-menu ul li ul li ul{
    border-left:1px solid #002D3F;
}
app-nav-menu ul li ul .menu-text {
    margin-right: 8px;
}
app-nav-menu ul li ul a {
    padding-right: 25px;
}
app-nav-menu ul li ul .app-icone-seta-unica-baixo {
    margin-left: 5px;
    margin-right: 0;

    position: absolute;
    top: 8px;
    right: 10px;
    padding: 0;
}
app-nav-menu ul li ul .app-icone-seta-unica-baixo:before {
    content: "\f105";
}

/*MENU*/
@media (max-width: 768px){
    app-nav-menu.fechado{
        display: none;
    }
    app-nav-menu ul{
        background-color: #024762;
        left: 0;
        width: 100%;
    }
    app-nav-menu ul li{
        width: 100%;
    }
    app-nav-menu ul li ul{
        background-color: #333;
        position: relative;
        visibility: visible;
    }
    app-nav-menu ul li ul li a:before{
        content: '•';
        color: #fff;
        float: left;
        margin-right: 5px;
    }
    app-nav-menu ul li:hover>ul{
        display: block;
    }
}